import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import axiosConfig from '../axiosConfig';

const FilterSearchContext = createContext();

export const FilterSearchProvider = ({ children }) => {

    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;

        fetchQualifications();
        fetchSkillSet();
        fetchJobTypes();
        fetchJobCategories();

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const [distanceInRadius, setDistanceInRadius] = useState({});
    const [selectedUserItems, setSelectedUserItems] = useState('');
    const [selectedHrItems, setSelectedHrItems] = useState('');

    const [userFilterData, setUserFilterData] = useState({});

    const [userFilterDataAll, setUserFilterDataAll] = useState({
        Qualifications: [],
        SkillSet: [],
        YearsOfExperience: [
            { id: 1, title: "0 - 6 Months", value: 6 },
            { id: 2, title: "6 Months - 1 Year", value: 12 },
            { id: 3, title: "1 Year - 2 Years", value: 24 },
            { id: 4, title: "2 Years - 3 Years", value: 36 },
            { id: 5, title: "3 Years - 4 Years", value: 48 },
            { id: 6, title: "4 Years - 5 Years", value: 60 },
            { id: 7, title: "5 Years - 6 Years", value: 72 },
            { id: 8, title: "6 Years - 7 Years", value: 84 },
            { id: 9, title: "7 Years - 8 Years", value: 96 },
            { id: 10, title: "8 Years - 9 Years", value: 108 },
            { id: 11, title: "9 Years - 10 Years", value: 120 },
            { id: 12, title: "10 Years - 11 Years", value: 132 },
            { id: 13, title: "11 Years - 12 Years", value: 144 },
            { id: 14, title: "12 Years - 13 Years", value: 156 },
            { id: 15, title: "13 Years - 14 Years", value: 168 },
            { id: 16, title: "14 Years - 15 Years", value: 180 }
        ],
        JobType: [],
        JobCategory: [],
    });

    const [hrFilterData, setHrFilterData] = useState({});

    const [hrFilterDataAll, setHrFilterDataAll] = useState({
        YearsOfExperience: [
            { id: 1, title: "0 - 6 Months", value: 6 },
            { id: 2, title: "6 Months - 1 Year", value: 12 },
            { id: 3, title: "1 Year to 2 Years", value: 24 },
            { id: 4, title: "2 Years to 3 Years", value: 36 },
            { id: 5, title: "3 Years to 4 Years", value: 48 },
            { id: 6, title: "4 Years to 5 Years", value: 60 },
            { id: 7, title: "5 Years to 6 Years", value: 72 },
            { id: 8, title: "6 Years to 7 Years", value: 84 },
            { id: 9, title: "7 Years to 8 Years", value: 96 },
            { id: 10, title: "8 Years to 9 Years", value: 108 },
            { id: 11, title: "9 Years to 10 Years", value: 120 },
            { id: 12, title: "10 Years to 11 Years", value: 132 },
            { id: 13, title: "11 Years to 12 Years", value: 144 },
            { id: 14, title: "12 Years to 13 Years", value: 156 },
            { id: 15, title: "13 Years to 14 Years", value: 168 },
            { id: 16, title: "14 Years to 15 Years", value: 180 }
        ],
        Qualifications: [],
        FieldOfStudies: [],
        SkillSet: [],
    });

    const handleUserFilterClear = () => {
        setUserFilterData({});
        setSelectedUserItems('');
        setDistanceInRadius({});
        console.log('handleUserFilterClear clicked');
        sessionStorage.setItem('userFilterData', JSON.stringify({}));
    };

    const handleHrFilterClear = () => {
        setHrFilterData({});
        setSelectedHrItems('');
        setDistanceInRadius({});
        console.log('handleHrFilterClear clicked');
    };

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('userFilterData');

        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);

            setUserFilterData(parsedUserData);

            const selectedItemsWithoutRadius = Object.keys(parsedUserData)
                .filter((key) => key !== 'DistanceInRadius')
                .reduce((acc, key) => {
                    acc[key] = parsedUserData[key];
                    return acc;
                }, {});
            setSelectedUserItems(selectedItemsWithoutRadius);

            if (parsedUserData.DistanceInRadius) {
                setDistanceInRadius(parsedUserData.DistanceInRadius);
            }
        }
    }, []);

    useEffect(() => {
        console.log('This is the distance in radius:', distanceInRadius);
    }, [distanceInRadius]);

    const handleUserFilterSave = async () => {
        try {
            // Remove categories with empty arrays, but keep DistanceInRadius if it has data
            const filteredSelectedItems = Object.keys(selectedUserItems).reduce((acc, category) => {
                if (Array.isArray(selectedUserItems[category]) && selectedUserItems[category].length > 0) {
                    acc[category] = selectedUserItems[category];
                }

                return acc;
            }, {});

            filteredSelectedItems.DistanceInRadius = distanceInRadius;

            sessionStorage.setItem('userFilterData', JSON.stringify(filteredSelectedItems));

            setUserFilterData(filteredSelectedItems);
            console.log('User Filter data:', filteredSelectedItems);

        } catch (error) {
            console.error('Error saving user filter data:', error);
        }
    };


    const handleHrFilterSave = async () => {
        // try {
        //     // Remove categories with empty arrays, but keep DistanceInRadius if it has data
        //     const filteredSelectedItems = Object.keys(selectedUserItems).reduce((acc, category) => {
        //         if (Array.isArray(selectedUserItems[category]) && selectedUserItems[category].length > 0) {
        //             acc[category] = selectedUserItems[category];
        //         } else if (category === 'DistanceInRadius' && selectedUserItems[category]
        //             && selectedUserItems[category].latitude &&
        //             selectedUserItems[category].longitude &&
        //             selectedUserItems[category].radius) {
        //             acc[category] = selectedUserItems[category];
        //         }
        //         return acc;
        //     }, {});

        //     setUserFilterData(filteredSelectedItems);
        //     console.log('User Filter data:', filteredSelectedItems);

        // } catch (error) {
        //     console.error('Error saving user filter data:', error);
        // }
    };

    const fetchQualifications = async () => {
        try {
            const response = await axiosConfig.get('/qualifications-list-web', {});
            if (response.data.success && isMountedRef.current) {
                const qualificationData = (response.data.data || []);
                setUserFilterDataAll(prevState => ({
                    ...prevState,
                    Qualifications: qualificationData.map(item => ({
                        id: item.id,
                        title: item.title,
                        value: item.title
                    }))
                }));
                console.log('Fetched qualifications:', qualificationData);
            } else if (!response.data.success) {
                console.error('Failed to fetch qualifications');
            }
        } catch (error) {
            console.error('Error fetching qualifications:', error);
        }
    };

    const fetchSkillSet = async () => {
        try {
            const response = await axiosConfig.get('/skills-list-web', {});
            if (response.data.success && isMountedRef.current) {
                const skillsData = (response.data.data || []);
                setUserFilterDataAll(prevState => ({
                    ...prevState,
                    SkillSet: skillsData.map(item => ({
                        id: item.id,
                        title: item.title,
                        value: item.title
                    }))
                }));
                console.log(`Fetched skills:`, skillsData.map(skill => ({ id: skill.id, title: skill.title })));
            } else if (!response.data.success) {
                console.error('Failed to fetch skills');
            }
        } catch (error) {
            console.error('Error fetching skills:', error);
        }
    };

    const fetchJobTypes = async () => {
        try {
            const response = await axiosConfig.get('/jobtypes-list-web', {});
            if (response.data.success && isMountedRef.current) {
                const jobTypeData = (response.data.data || []);
                setUserFilterDataAll(prevState => ({
                    ...prevState,
                    JobType: jobTypeData.map(item => ({
                        id: item.id,
                        title: item.title,
                        value: item.title
                    }))
                }));
                console.log('Fetched job types:', jobTypeData);
            } else if (!response.data.success) {
                console.error('Failed to fetch job types');
            }
        } catch (error) {
            console.error('Error fetching job types:', error);
        }
    };

    const fetchJobCategories = async () => {
        try {
            const response = await axiosConfig.get('/jobcategories-list-web', {});
            if (response.data.success && isMountedRef.current) {
                const jobCategoryData = (response.data.data || []);
                setUserFilterDataAll(prevState => ({
                    ...prevState,
                    JobCategory: jobCategoryData.map(item => ({
                        id: item.id,
                        title: item.title,
                        value: item.title
                    }))
                }));
                console.log('Fetched job categories:', jobCategoryData);
            } else if (!response.data.success) {
                console.error('Failed to fetch job categories');
            }
        } catch (error) {
            console.error('Error fetching job categories:', error);
        }
    };

    return (
        <FilterSearchContext.Provider
            value={{
                userFilterData,
                userFilterDataAll,
                handleUserFilterSave,
                handleUserFilterClear,
                distanceInRadius,
                setDistanceInRadius,
                selectedUserItems,
                setSelectedUserItems,
                hrFilterData,
                hrFilterDataAll,
                handleHrFilterSave,
                handleHrFilterClear,
                selectedHrItems,
                setSelectedHrItems,
            }}
        >
            {children}
        </FilterSearchContext.Provider>
    );
};

export const useFilterSearch = () => {
    return useContext(FilterSearchContext);
};