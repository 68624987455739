// import React, { useState } from "react";

// const GlobalContext = React.createContext();

// const GlobalProvider = ({ children }) => {
//   const [themeDark, setThemeDark] = useState(false);
//   const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
//   const [applicationModalVisible, setApplicationModalVisible] = useState(false);
//   const [signInModalVisible, setSignInModalVisible] = useState(false);
//   const [signUpModalVisible, setSignUpModalVisible] = useState(false);
//   const [videoModalVisible, setVideoModalVisible] = useState(false);
//   const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
//   const [user, setUser] = useState(null); // Track the user state
//   const [header, setHeader] = useState({
//     theme: "light",
//     bgClass: "default",
//     variant: "primary",
//     align: "left",
//     isFluid: false,
//     button: "cta", // profile, account, null
//     buttonText: "Get started free", // profile, account, null
//     reveal: true,
//   });
//   const [footer, setFooter] = useState({
//     theme: "dark",
//     style: "style1", //style1, style2
//   });

//   const toggleTheme = () => {
//     setThemeDark(!themeDark);
//   };

//   const toggleSidebarDashboard = () => {
//     setShowSidebarDashboard(!showSidebarDashboard);
//   };

//   const toggleVideoModal = () => {
//     setVideoModalVisible(!videoModalVisible);
//   };

//   const toggleApplicationModal = () => {
//     setApplicationModalVisible(!applicationModalVisible);
//   };

//   const toggleSignInModal = () => {
//     setSignInModalVisible(!signInModalVisible);
//   };

//   const toggleSignUpModal = () => {
//     setSignUpModalVisible(!signUpModalVisible);
//   };

//   const toggleOffCanvas = () => {
//     setVisibleOffCanvas(!visibleOffCanvas);
//   };

//   const closeOffCanvas = () => {
//     setVisibleOffCanvas(false);
//   };

//   return (
//     <GlobalContext.Provider
//       value={{
//         themeDark,
//         toggleTheme,
//         showSidebarDashboard,
//         toggleSidebarDashboard,
//         videoModalVisible,
//         toggleVideoModal,
//         applicationModalVisible,
//         toggleApplicationModal,
//         signInModalVisible,
//         toggleSignInModal,
//         signUpModalVisible,
//         toggleSignUpModal,
//         visibleOffCanvas,
//         toggleOffCanvas,
//         closeOffCanvas,
//         header,
//         setHeader,
//         footer,
//         setFooter,
//         user, // Provide user state
//         setUser, // Provide function to update user state
//       }}
//     >
//       {children}
//     </GlobalContext.Provider>
//   );
// };

// export default GlobalContext;
// export { GlobalProvider };


import React, { useEffect, useState } from "react";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false);
  const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [otpVerifyModalVisible, setOtpVerifyModalVisible] = useState(false);
  const [mobNumVerifyModalVisible, setMobNumVerifyModalVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [hrSignUpModalVisible, setHrSignUpModalVisible] = useState(false);
  const [signUpSelectionModalVisible, setSignUpSelectionModalVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);

  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [isJobProvider, setIsJobProvider] = useState(false);


  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    const storedUser = sessionStorage.getItem('user');
    const storedProfile = sessionStorage.getItem('profile');
    const storedIsJobProvider = sessionStorage.getItem('isJobProvider');

    if (storedToken) {
      setToken(storedToken);
      console.log("Token in gContext:", storedToken);
    }
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      console.log("User in gContext:", parsedUser);
    }
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile);
      setProfile(parsedProfile);
      console.log("Profile in gContext:", parsedProfile);
    }
    if (storedIsJobProvider) {
      const isProvider = storedIsJobProvider === 'true';
      setIsJobProvider(isProvider);
      console.log("Is Job Provider in gContext:", isProvider);
    }
  }, []);

  const [header, setHeader] = useState({
    theme: "light",
    bgClass: "default",
    variant: "primary",
    align: "left",
    isFluid: false,
    button: "cta", // profile, account, null
    buttonText: "Get started free", // profile, account, null
    reveal: true,
  });
  const [footer, setFooter] = useState({
    theme: "dark",
    style: "style1", //style1, style2
  });

  const toggleTheme = () => {
    setThemeDark(!themeDark);
  };

  const toggleSidebarDashboard = () => {
    setShowSidebarDashboard(!showSidebarDashboard);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleApplicationModal = () => {
    setApplicationModalVisible(!applicationModalVisible);
  };

  const toggleSignInModal = () => {
    setSignInModalVisible(!signInModalVisible);
  };

  const toggleSignUpModal = () => {
    setSignUpModalVisible(!signUpModalVisible);
  };

  const toggleOtpVerifyModal = () => {
    setOtpVerifyModalVisible(!otpVerifyModalVisible);
  };

  const toggleMobNumVerifyModal = () => {
    setMobNumVerifyModalVisible(!mobNumVerifyModalVisible);
  };

  const toggleHrSignUpModal = () => {
    setHrSignUpModalVisible(!hrSignUpModalVisible);
  };

  const toggleSignUpSelectionModal = () => {
    setSignUpSelectionModalVisible(!signUpSelectionModalVisible);
  };

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModalVisible(!forgotPasswordModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        showSidebarDashboard,
        toggleSidebarDashboard,
        videoModalVisible,
        toggleVideoModal,
        applicationModalVisible,
        toggleApplicationModal,
        signInModalVisible,
        toggleSignInModal,
        mobNumVerifyModalVisible,
        toggleMobNumVerifyModal,
        otpVerifyModalVisible,
        toggleOtpVerifyModal,
        signUpModalVisible,
        toggleSignUpModal,
        hrSignUpModalVisible,
        toggleHrSignUpModal,
        signUpSelectionModalVisible,
        toggleSignUpSelectionModal,
        forgotPasswordModalVisible,
        toggleForgotPasswordModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        header,
        setHeader,
        footer,
        setFooter,
        userName,
        setUserName,
        user, // Provide user state
        setUser, // Provide function to update user state
        profile,
        setProfile,
        token,
        setToken,
        isJobProvider,
        setIsJobProvider,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
