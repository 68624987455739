export const menuItems = [
  {
    // name: "home",
    name: "",
    label: "Home",

  },
  {
    name: "about",
    label: "About Us",

  },
  {
    name: "services",
    label: "Services",

  },
  {
    name: "joblisting",
    label: "Job List",

  },
  {
    name: "saved-joblisting",
    label: "Saved Jobs",

  },
  {
    name: "pages",
    label: "Pages",
    items: [
      {
        name: "job-pages",
        label: "Job Pages",
        items: [
          // { name: "search-grid", label: "Job Grid" },
          // { name: "search-list", label: "Job List" },
          // { name: "job-details", label: "Job Details" },
          { name: "joblisting", label: "Job List" },
          { name: "joblisting-grid", label: "Job Grid" },
          { name: "saved-joblisting", label: "Saved Job List" },
          { name: "saved-joblisting-grid", label: "Saved Job Grid" },
          { name: "featured-joblisting", label: "Featured Job List" },
          { name: "featured-joblisting-grid", label: "Featured Job Grid" },
          { name: "recommended-joblisting", label: "Recommended Job List" },
          { name: "recommended-joblisting-grid", label: "Recommended Job Grid" },
        ],
      },
      {
        name: "dashboard",
        label: "Dashboard",
        items: [
          { name: "dashboard-main", label: "Dashboard Main" },
          { name: "dashboard-settings", label: "Dashboard Settings" },
          {
            name: "dashboard-applicants",
            label: "Dashboard Applicants",
          },
          { name: "dashboard-jobs", label: "Dashboard Posted Jobs" },
        ],
      },
      {
        name: "candidate",
        label: "Candidate Pages",
        items: [
          { name: "candidate-profile", label: "Candidate Profile 01" },
          { name: "candidate-profile-2", label: "Candidate Profile 02" },
        ],
      },
      {
        name: "search",
        label: "Search Pages",
        items: [
          { name: "search-grid", label: "Search Grid" },
          { name: "search-list", label: "Search List 01" },
          { name: "search-list-2", label: "Search List 02" },
        ],
      },
      {
        name: "company-profile",
        label: "Company Profile",
      },

      {
        name: "essential",
        label: "Essential",
        items: [
          { name: "faq", label: "FAQ" },
          { name: "404", label: "404" },
          { name: "pricing", label: "Pricing" },
          { name: "contact", label: "Contact" },
        ],
      },
    ],
  },
  {
    name: "contact",
    label: "Contact",

  },

];
