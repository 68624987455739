import React, { useEffect, useState } from "react";
import axiosConfig from '../../axiosConfig';
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/logo-main-black.png";
import imgL1LogoWhite from "../../assets/image/logo-main-white.png";

const Logo = ({ white, height = '200px', width = '300px', className = "", ...rest }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axiosConfig.get('/logo');
        if (response.data.success) {
          setLogo(response.data.data.image);
        } else {
          console.error('Failed to fetch logo');
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  return (
    <Link to="/" className={`d-block mm ${className}`} {...rest}>
      {logo ? (
        <img 
          src={logo} 
          alt="Logo" 
          style={{ height: height, width: width }}
        />
      ) : (
        <img 
          src={white ? imgL1LogoWhite : imgL1Logo} 
          alt="Logo" 
          style={{ height: height, width: width }}
        />
      )}
    </Link>
  );
};

export default Logo;
