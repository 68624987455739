import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';
import axios from "axios";
import network from "../../../constants/Network";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalForgotPassword = (props) => {
  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [verifyForm, setVerifyForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [mobile_number, setMobile_number] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [password_confirmation, setPassword_confirmation] = useState('');
  const [otp, setOtp] = useState('');
  const [showPass, setShowPass] = useState(true);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  // useEffect(() => {
  //   if (gContext.userName) {
  //     setMobile_number(gContext.userName);
  //   }
  // }, [gContext.userName]);

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    gContext.toggleForgotPasswordModal();
    // gContext.setUserName(null);
    setMobile_number('');
    setNewPassword('');
    setPassword_confirmation('');
    setOtp('');
    setVerifyForm(false);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleNumberSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle number submit pressed');

    setErrors({});

    let isValid = true;

    const mobileRegex = /^\d{10}$/;
    if (!mobile_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Mobile number is required"
      }));
      isValid = false;
    } else if (!mobileRegex.test(mobile_number)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Enter a valid mobile number"
      }));
      isValid = false;
    }

    if (!new_password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        new_password: 'Password is required'
      }));
      isValid = false;
    }

    if (new_password !== password_confirmation) {
      setErrors(prevErrors => ({
        ...prevErrors,
        new_password: 'Passwords do not match',
        password_confirmation: 'Passwords do not match'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the number data:', mobile_number, new_password, password_confirmation);

    try {
      const formData = new FormData();
      formData.append('mobile_number', mobile_number);
      formData.append('new_password', new_password);

      const response = await axios.post(`${network.serverip}/api/forgot-password-otp-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        handleSuccessMessage(response.data.message);
        setErrors({});
        setVerifyForm(true);
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while generating OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpGenerateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpGenerateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle otp submit pressed');

    setErrors({});

    let isValid = true;

    if (!otp || otp.length !== 6) {
      setErrors(prevErrors => ({
        ...prevErrors,
        otp: 'Please enter a valid 6-digit OTP'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the mobile number & otp:', mobile_number, otp);

    try {
      const formData = new FormData();
      formData.append('mobile_number', mobile_number);
      formData.append('otp', otp);
      formData.append('new_password', new_password);

      const response = await axios.post(`${network.serverip}/api/forgot-password-verifyOtp-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        setErrors({});
        setVerifyForm(false);
        setMobile_number('');
        setNewPassword('');
        setPassword_confirmation('');
        setOtp('');
        handleSuccessMessage(response.data.message);
        gContext.toggleForgotPasswordModal();
        gContext.toggleSignInModal();
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while verifying OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpVerifyError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpVerifyError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();

    console.log('Handle otp resend pressed');

    setErrors({});

    let isValid = true;

    if (!mobile_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: 'Mobile number is required'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the mobile number resend:', mobile_number);

    try {
      const response = await apiClient.post('resend-mobile-otp-web', {
        mobile_number: mobile_number,
      });

      console.log('This is the response:', response);

      if (response.data.message) {
        setErrors({});
        setOtp('');
        handleSuccessMessage(response.data.message);
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while resending OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpVerifyError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpVerifyError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const styles = ModalForgotPasswordStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.forgotPasswordModalVisible}
      onHide={gContext.toggleForgotPasswordModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Welcome Back
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Follow the steps to reset your password.
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">295</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New jobs posted today
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">14</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New companies registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                {!verifyForm ? (
                  <form onSubmit={handleNumberSubmit}>
                    <div className="form-group">

                      <div className="w-100 d-flex justify-content-center">
                        <p className="font-size-4 text-success min-height-40">
                          {successMessage || ''}
                        </p>
                      </div>

                      <label
                        htmlFor="mobile_number"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Mobile number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter mobile number"
                        id="mobile_number"
                        name="mobile_number"
                        value={mobile_number}
                        onChange={(e) => setMobile_number(e.target.value)}
                      />
                      {errors?.mobile_number ? <p className="font-size-3 text-danger">
                        {errors.mobile_number}
                      </p> : null}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="new_password"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        New Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPass ? "password" : "text"}
                          className="form-control"
                          id="new_password"
                          name="new_password"
                          placeholder="Enter new password"
                          value={new_password}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <a
                          href="/#"
                          className="show-password pos-abs-cr fas mr-6 text-black-2"
                          onClick={(e) => {
                            e.preventDefault();
                            togglePassword();
                          }}
                        >
                          <span className="d-none">none</span>
                        </a>
                      </div>
                      {errors?.new_password ? <p className="font-size-3 text-danger">
                        {errors.new_password}
                      </p> : null}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="password_confirmation"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Confirm New Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPass ? "password" : "text"}
                          className="form-control"
                          id="password_confirmation"
                          name="password_confirmation"
                          placeholder="Confirm new password"
                          value={password_confirmation}
                          onChange={(e) => setPassword_confirmation(e.target.value)}
                        />
                        <a
                          href="/#"
                          className="show-password pos-abs-cr fas mr-6 text-black-2"
                          onClick={(e) => {
                            e.preventDefault();
                            togglePassword();
                          }}
                        >
                          <span className="d-none">none</span>
                        </a>
                      </div>
                      {errors?.password_confirmation ? <p className="font-size-3 text-danger">
                        {errors.password_confirmation}
                      </p> : null}
                    </div>

                    <div className="form-group mb-8">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                            Submit
                          </button>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.otpGenerateError ? (
                            <p className="font-size-3 text-danger text-center">
                              {errors.otpGenerateError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>


                ) : (

                  <form onSubmit={handleOtpSubmit}>
                    <div className="form-group">

                      <div className="w-100 d-flex justify-content-center">
                        <p className="font-size-4 text-success min-height-40">
                          {successMessage || ''}
                        </p>
                      </div>

                      <label
                        htmlFor="mobile_number"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Mobile number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter mobile number"
                        id="mobile_number"
                        name="mobile_number"
                        value={mobile_number}
                        // onChange={(e) => setMobile_number(e.target.value)}
                        readOnly
                      />
                      {errors?.mobile_number ? <p className="font-size-3 text-danger">
                        {errors.mobile_number}
                      </p> : null}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="otp"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Enter 6 digit OTP sent to your email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter otp"
                        id="otp"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      {errors?.otp ? <p className="font-size-3 text-danger">
                        {errors.otp}
                      </p> : null}
                    </div>

                    <div className="form-group mb-8">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                            Verify
                          </button>
                        </div>
                        <div className="col-12 d-flex flex-column align-items-center">
                          {errors?.new_password && (
                            <p className="font-size-3 text-danger">
                              {errors.new_password}
                            </p>
                          )}
                          {errors?.otpVerifyError && (
                            <p className="font-size-3 text-danger">
                              {errors.otpVerifyError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-4">
                      <p className="font-size-3 text-black mb-0">
                        Didn't receive OTP Code!
                      </p>
                      <button
                        type="button"
                        className="font-size-3 font-weight-bold text-dodger text-center ml-2"
                        style={{ ...styles.btnAsText, textDecoration: 'underline' }}
                        onClick={handleResendOtp}
                      >
                        Resend
                      </button>
                    </div>
                  </form>
                )}

              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalForgotPassword;

function ModalForgotPasswordStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      // font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}