// src/axiosConfig.js
import axios from 'axios';
import network from '../constants/Network';

// const baseURL = typeof window !== 'undefined' 
//   ? (window.location.hostname.includes('localhost') 
//       ? 'http://localhost:8000/api' 
//       : 'https://devadmin.stackle.in/api') 
//   : 'http://localhost:8000/api';
  
// const baseURL = 'https://devadmin.stackle.in/api';
const baseURL = `${network.serverip}/api`;
const apiClient = axios.create({
  baseURL: baseURL,
  // You can add more default configurations here, like headers, timeouts, etc.
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;